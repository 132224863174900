
.inputDisabled {
  background-color: transparent;
  border: none;
  color: white;
}

.inputUndisabled {
  background-color: white;
  border-radius: 0.25rem;
  color: black;
}

.cursor {
  cursor: pointer;
  display: block;
  margin: auto;
}

.bg-gray-800 {
  background-color: #49494b;
  border: 1px solid #343A40FF;
  &:focus,
  &:active {
    background-color: #414547;
  }
}
