.Footer {
  position: relative;

}
  .concave-curve {
    bottom: -20px;
    position: absolute;
    height: auto;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

.button {
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  padding: 1.2em 3em;
  text-align: center;
  transition: all .25s cubic-bezier(.17, .67, .39, .99)
}

.button-main {
  box-shadow: rgba(252, 67, 0, .67) 0 0 6px 0;
  background: linear-gradient(90deg, #f81d00 0, #f60 100%)
}

.button-main:hover {
  box-shadow: rgba(252, 67, 0, .5) 0 0 14px 0;
  filter: brightness(1.2);
  color: #fff;
  text-decoration: none
}

.attribute {
  background: rgba(18,18,24,.9);
  color: #8a92aa;
  padding: 25px 0;
  height: 90px;
  display: block;
  padding-top: 35px;
}

#footer {
  padding: 20px 0 50px 0
}

@media (min-width:768px) {
  #footer .container {
    max-width: 1340px
  }
}

#footer .footer-logo {
  height: 70px;
  margin-bottom: 25px
}

#footer .basic-text {
  font-size: 12px;
  line-height: 26px
}

#footer .footer-nav {
  list-style-type: none;
  padding-left: 0
}

#footer .footer-nav .menu-item {
  color: #8a92aa;
  font-size: 14px;
  margin-bottom: 5px
}

#footer .footer-nav .menu-link {
  color: #8a92aa;
  transition: all .2s linear;
  font-size: 14px
}

#footer .footer-nav .menu-link:hover {
  color: #8a92aa;
  background: -webkit-linear-gradient(#f81d00, #f60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

@media (max-width:767.98px) {
  #footer {
    padding: 35px 0
  }
  #footer .col-12 {
    margin-bottom: 20px
  }
  #footer .col-12:last-child {
    margin-bottom: 0
  }
  #footer .text-right {
    text-align: left
  }
}

@media (max-width:767.98px) {
  #footer {
    text-align: center!important
  }
  #footer .text-right {
    text-align: center!important
  }
}

.attribute {
  background: rgba(18, 18, 24, .9);
  color: #8a92aa;
  padding: 25px 0;
  height: 90px;
  display: block;
  padding-top: 35px
}

.attribute .container {
  position: relative
}

.attribute .tk {
  position: absolute;
  right: 15px;
  bottom: -12px
}

.attribute .tk img {
  height: 45px
}

@media (max-width:991.98px) {
  .attribute .tk {
    position: relative;
    margin: auto;
    right: unset;
    bottom: unset
  }
}

.attribute .copyright {
  text-align: center
}

@media (max-width:991.98px) {
  .attribute .copyright {
    margin: auto;
    margin-bottom: 15px
  }
}

@media (max-width:991.98px) {
  .attribute {
    height: auto
  }
}

.basic-text {
  font-size: 12px;
  line-height: 26px;
  color: #8a92aa;
}