.header-packs {
  height: 550px;
  position: relative;
}

.convex-curve {
  bottom: -20px;
  position: absolute;
  height: auto;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
}

.contact {
  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    transition: all .2s;

    &:hover {
      color: #f81d00;
      background: -webkit-linear-gradient(#f81d00,#f60);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  span {
    color: #8a92aa;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 0;
  }
}